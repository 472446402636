import { workstate } from '.';
import { apiAxios } from 'src/util';

export const getLoInfo = (referralToken) => (dispatch) => {
  return workstate(dispatch, 'fetch-lo-info', async () => {
    if (referralToken === 'default') {
      dispatch({
        type: 'set_lo_data',
        data: 0,
      });
    } else {
      try {
        const res = await apiAxios.get(`loan-officers/${referralToken}`);
        dispatch({
          type: 'set_lo_data',
          data: {
            ...res.data,
            Found: true,
          },
        });
      } catch (e) {
        dispatch({
          type: 'set_lo_data',
          data: {
            Found: false,
          },
        });
      }
    }
  });
};

export const getLoanOfficerById = (id, params) => (dispatch) => {
  return workstate(dispatch, 'fetch-lo-info', async () => {
    // ex. params = { bool licenses, bool personas }
    const res = await apiAxios.get(`loan-officers/${id}`, { params });
    dispatch({
      type: 'set_lo_data',
      data: res.data,
    });
  });
};

export const getCurrentLoanOfficer = () => (dispatch) => {
  return workstate(dispatch, 'fetch-lo-info', async () => {
    const res = await apiAxios.get('loan-officers/me');
    dispatch({
      type: 'set_lo_data',
      data: res.data,
    });
  });
};

export const getLoNamesAndReferralTokens = () => (dispatch) => {
  return workstate(dispatch, 'get-lo-names', async () => {
    return await apiAxios.get('loan-officers');
  });
};

export const getLoanOfficerFromLoan = (loanGuid) => (dispatch) => {
  return workstate(dispatch, 'get-loan-officer-from-loan', async () => {
    try {
      const res = await apiAxios.get(`loan-officers/loan/${loanGuid}`);
      if (res.data) {
        dispatch({
          type: 'set_lo_data',
          data: {
            ...res.data,
            Found: true,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: 'set_lo_data',
        data: {
          Found: false,
        },
      });
      throw new Error(
        'Error likely due to Encompass credentials not matching with a loan officer: ' +
          e.response?.data?.messages?.[0]
      );
    }
  });
};
export const getflaxasExcludeLO = async (loanGuid) => {
  const resp = await apiAxios.get(
    `loan-officers/exclude-from-flaxas/${loanGuid}`
  );
  return resp.data;
};
