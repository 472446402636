import { Dispatch } from 'redux';
import { workstate } from 'src/actions';
import {
  Residence,
  ResidenceActionType,
} from 'src/components/Loan/Application/Sections/BorrowerInfo/Inputs/Residence/store/types';
import { BorrowerOrCoBorrowerType, Guid } from 'src/types';
import { apiAxios, hasAnyChanges, mergeDeep } from 'src/util';

export const createResidence =
  (
    loanId: Guid,
    applicationId: Guid,
    applicantType: BorrowerOrCoBorrowerType,
    data?: Residence,
  ) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'create-residence', async () => {
      const res = await apiAxios.post(
        `loans/${loanId}/applications/${applicationId}/${applicantType.toLowerCase()}/residences`,
        data,
      );

      dispatch({
        type: ResidenceActionType.ADD,
        data: {
          ...res.data,
          owner: applicantType,
        },
      });
    });
  };

export const updateResidence =
  (
    loanId: Guid,
    applicationId: Guid,
    applicantType: BorrowerOrCoBorrowerType,
    residenceId: string,
    data: Residence,
  ) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'update-residence', async () => {
      if (!hasAnyChanges(data)) return;

      dispatch({
        type: ResidenceActionType.UPDATE,
        data: { ...data, id: residenceId },
      });

      const res = await apiAxios.patch(
        `loans/${loanId}/applications/${applicationId}/${applicantType.toLowerCase()}/residences/${residenceId}`,
        data,
      );

      dispatch({
        type: ResidenceActionType.UPDATE,
        data: mergeDeep(data, res.data),
      });
    });
  };

export const deleteResidence =
  (
    loanId: Guid,
    applicationId: Guid,
    applicantType: BorrowerOrCoBorrowerType,
    residenceId: string,
  ) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'delete-residence', async () => {
      await apiAxios.delete(
        `loans/${loanId}/applications/${applicationId}/${applicantType.toLowerCase()}/residences/${residenceId}`,
      );

      dispatch({
        type: ResidenceActionType.DELETE,
        data: residenceId,
      });
    });
  };
