import { useState, useEffect } from 'react';
import { useDebouncedCallback as libDebounced } from 'use-debounce';

export const useDebounce = (v, d) => {
  const [dvalue, setdvalue] = useState(v);
  useEffect(() => {
    const timer = setTimeout(() => {
      setdvalue(v);
    }, d);
    return () => clearTimeout(timer);
  }, [v, d]);

  return dvalue;
};

export function useDebounceCallback (callback, delay, immediate = false) {
  const debouncedFunction = libDebounced(callback, delay, {
    leading: immediate,
    trailing: !immediate,
  });

  // Cleanup effect to flush pending calls on unmount
  useEffect(() => {
    return () => {
      if (debouncedFunction.isPending()) {
        debouncedFunction.flush();
      }
    };
  }, [debouncedFunction]);

  return debouncedFunction;
}
