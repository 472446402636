import { Dispatch } from 'redux';
import { workstate } from 'src/actions';
import { Guid } from 'src/types';
import { apiAxios, hasAnyChanges, mergeDeep } from 'src/util';
import { Liability, LiabilityActionType } from './types';

export const createLiability =
  (loanId: Guid, applicationId: Guid, data?: Liability) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'create-liability', async () => {
      const res = await apiAxios.post(
        `loans/${loanId}/applications/${applicationId}/liabilities`,
        data,
      );

      dispatch({
        type: LiabilityActionType.ADD,
        data: res.data,
      });
    });
  };

export const updateLiability =
  (loanId: Guid, applicationId: Guid, liabilityId: Guid, data: Liability) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'update-liability', async () => {
      if (!hasAnyChanges(data)) return;

      dispatch({
        type: LiabilityActionType.UPDATE,
        data: { ...data, id: liabilityId },
      });

      const res = await apiAxios.patch(
        `loans/${loanId}/applications/${applicationId}/liabilities/${liabilityId}`,
        data,
      );

      dispatch({
        type: LiabilityActionType.UPDATE,
        data: mergeDeep(data, res.data),
      });
    });
  };

export const deleteLiability =
  (loanId: Guid, applicationId: Guid, liabilityId: Guid) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'delete-liability', async () => {
      await apiAxios.delete(
        `loans/${loanId}/applications/${applicationId}/liabilities/${liabilityId}`,
      );

      dispatch({
        type: LiabilityActionType.DELETE,
        data: liabilityId,
      });
    });
  };
