import { Dispatch } from 'redux';
import { workstate } from 'src/actions';
import { Guid } from 'src/types';
import { apiAxios, hasAnyChanges, mergeDeep } from 'src/util';
import { OtherAsset, OtherAssetActionType } from './types';

export const createOtherAsset =
  (loanId: Guid, applicationId: Guid, data?: OtherAsset) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'create-otherasset', async () => {
      const res = await apiAxios.post(
        `loans/${loanId}/applications/${applicationId}/otherAssets`,
        data,
      );

      dispatch({
        type: OtherAssetActionType.ADD,
        data: res.data,
      });
    });
  };

export const updateOtherAsset =
  (loanId: Guid, applicationId: Guid, otherAssetId: Guid, data: OtherAsset) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'update-otherasset', async () => {
      if (!hasAnyChanges(data)) return;

      dispatch({
        type: OtherAssetActionType.UPDATE,
        data: { ...data, id: otherAssetId },
      });

      const res = await apiAxios.patch(
        `loans/${loanId}/applications/${applicationId}/otherAssets/${otherAssetId}`,
        data,
      );

      dispatch({
        type: OtherAssetActionType.UPDATE,
        data: mergeDeep(data, res.data),
      });
    });
  };

export const deleteOtherAsset =
  (loanId: Guid, applicationId: Guid, otherAssetId: Guid) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'delete-otherasset', async () => {
      await apiAxios.delete(
        `loans/${loanId}/applications/${applicationId}/otherAssets/${otherAssetId}`,
      );

      dispatch({
        type: OtherAssetActionType.DELETE,
        data: otherAssetId,
      });
    });
  };
