import { Dispatch } from 'redux';
import { workstate } from 'src/actions';
import { ApplicationActionType } from 'src/components/Loan/Application/store/actionTypes';
import { BorrowerOrCoBorrowerType, Guid } from 'src/types';
import { Application } from 'src/types/application';
import { Borrower } from 'src/types/borrower';
import { apiAxios, hasAnyChanges, mergeDeep } from 'src/util';

const getStrippedApplication = (application: Application) => {
  // @ts-ignore
  delete application.borrower.residences;
  // @ts-ignore
  delete application.coBorrower.residences;
  // @ts-ignore
  delete application.borrower.urlaAlternateNames;
  // @ts-ignore
  delete application.coBorrower.urlaAlternateNames;
  return application;
};

export const updateApplication =
  (loanGuid: Guid, applicationId: Guid, data: Application) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'update-application', async () => {
      if (!hasAnyChanges(data)) return;

      dispatch({
        type: ApplicationActionType.UPDATE,
        data: { ...data, id: applicationId },
      });

      const res = await apiAxios.patch<Application>(
        `loans/${loanGuid}/applications/${applicationId}`,
        data,
      );

      dispatch({
        type: ApplicationActionType.UPDATE,
        data: mergeDeep(data, getStrippedApplication(res.data)),
      });
    });
  };

export const createApplication =
  (loanGuid: Guid, loanOfficerId: number, data: Application) =>
  (dispatch: Dispatch) => {
    return workstate(
      dispatch,
      'create-application',
      async () => {
        const res = await apiAxios.post<Application>(
          `loans/${loanGuid}/applications?loanOfficerId=${loanOfficerId}`,
          data,
        );

        dispatch({
          type: ApplicationActionType.ADD,
          data: getStrippedApplication(res.data),
        });
      },
      { handleError: false },
    );
  };

export const updateApplicationBorrower =
  (
    loanGuid: Guid,
    applicationId: Guid,
    applicantType: BorrowerOrCoBorrowerType,
    data: Borrower,
  ) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'update-application', async () => {
      if (!hasAnyChanges(data)) return;
      const requestBody = {
        [applicantType === BorrowerOrCoBorrowerType.Borrower
          ? 'borrower'
          : 'coBorrower']: data,
      };

      dispatch({
        type: ApplicationActionType.UPDATE,
        data: { ...requestBody, id: applicationId },
      });

      const res = await apiAxios.patch<Application>(
        `loans/${loanGuid}/applications/${applicationId}`,
        requestBody,
      );

      dispatch({
        type: ApplicationActionType.UPDATE,
        data: mergeDeep(requestBody, getStrippedApplication(res.data)),
      });
    });
  };
