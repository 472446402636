/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, lazy, Suspense } from 'react';
import './App.scss';
import FMLoader from 'src/components/FMLoader';
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import Header from 'src/components/Header';
import Footer from 'src/components/Footer';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { authconnect } from './Auth';
import {
  setLastVisitedLoanGuid,
  setWarning,
  setError,
  fetchBorrowerLoanApplications,
} from 'src/actions';
import env from 'src/env';
import { AnonFeatureFlagsProvider } from 'src/appconfig';
import { apiAxios } from 'src/util';
import { useConfirmation } from 'src/hooks';
import LogRocket from 'logrocket';
import ErrorBoundary from 'src/components/ErrorBoundary';

const LoanContainer = lazy(() => import('src/components/Loan'));
const VerifyAccount = lazy(() => import('./components/Account/VerifyAccount'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const ServerPicker = lazy(() =>
  import('src/components/development/ServerPicker')
);
const LoanValidationState = lazy(() =>
  import('src/components/development/LoanValidationState')
);
const InvitationCreator = lazy(() =>
  import('src/components/development/InvitationCreator')
);
const Test = lazy(() => import('src/components/development/Test'));
const SignupSignin = lazy(() => import('src/components/Account'));
const UserSettings = lazy(() => import('src/components/UserSettings'));
const ResetPassword = lazy(() =>
  import('src/components/Account/ResetPassword')
);
const RedeemInvitation = lazy(() =>
  import('src/components/Account/Invitation')
);
const Consent = lazy(() => import('src/components/Consent'));
const CrossAuth = lazy(() => import('src/components/CrossAuth'));
const guidMatchStr =
  '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

function App ({
  dispatch,
  isAuthenticated,
  handleAuthentication,
  functionServer,
  apiServer,
  session,
  lastLoanGuid,
  logout,
  warning,
  error,
  appReady,
}) {
  const isCallback = Boolean(useRouteMatch('/callback'));
  const history = useHistory();
  const location = useLocation();
  const confirm = useConfirmation();

  const guidRouteMatch = useRouteMatch(
    `/:section(apply|postapplication)/:guid(${guidMatchStr})`
  );
  const referralRouteMatch = useRouteMatch(
    '/register/:referralToken?/:guid?/:applicationIndex?/:pairFname?/:pairLname?/:pairEmail?'
  );
  const legacyReferralRouteMatch = useRouteMatch(
    '/CreateAccount/:referralToken?/:guid?/:applicationIndex?/:pairFname?/:pairLname?/:pairEmail?'
  );
  const invitationRouteMatch = useRouteMatch('/invite/:invitationHash?');
  const verificationRouteMatch = useRouteMatch(
    '/verify/:email?/:verificationCode?'
  );
  const consentRouteMatch = useRouteMatch('/consent/:hash');
  const isCallbackCrossAuth = useRouteMatch('/callback-cross-auth');

  useEffect(() => {
    (async () => {
      // temporary: can be removed after one week
      if (location.pathname.toLowerCase().includes('/cocreditconsent')) {
        history.replace(
          location.pathname.replace('cocreditconsent', 'consent')
        );
      } else if (
        isCallback &&
        !isAuthenticated &&
        /access_token|id_token|error/.test(location.hash) &&
        !location.pathname.toLowerCase().includes('/login') &&
        !location.pathname.toLowerCase().includes('/register') &&
        !location.pathname.toLowerCase().includes('/createaccount') &&
        !location.pathname.toLowerCase().includes('/reset-password') &&
        !location.pathname.toLowerCase().includes('/consent') &&
        !location.pathname.toLowerCase().includes('/callback-cross-auth') &&
        location.pathname !== '/'
      ) {
        await handleAuthentication();
        history.replace('/dashboard');
      }
    })();
  }, [isCallback, handleAuthentication, isAuthenticated]);

  useEffect(() => {
    Axios.defaults.baseURL = functionServer;
    apiAxios.defaults.baseURL = apiServer;
  }, [functionServer]);

  useEffect(() => {
    apiAxios.interceptors.request.use((config) => {
      if (
        LogRocket.sessionURL &&
        !LogRocket.sessionURL.includes('LogRocket.init()')
      ) {
        config.headers['X-LogRocket-URL'] = LogRocket.sessionURL;
      }
      return config;
    });
  }, []);

  useEffect(() => {
    if (guidRouteMatch) {
      dispatch(setLastVisitedLoanGuid(guidRouteMatch.params.guid));
    }
  }, [guidRouteMatch, session]);

  useEffect(() => {
    (async () => {
      if (warning) {
        await confirm({
          variant: 'alert',
          description: warning,
        });
        dispatch(setWarning(''));
      }
    })();
  }, [warning]);

  useEffect(() => {
    (async () => {
      if (error) {
        await confirm({
          variant: 'alert',
          description: error,
        });
        dispatch(setError(''));
      }
    })();
  }, [error]);

  useEffect(() => {
    if (isAuthenticated && !session?.isLoanOfficer) {
      dispatch(fetchBorrowerLoanApplications());
    }
  }, [isAuthenticated, session?.isLoanOfficer]);

  useEffect(() => {
    if (isAuthenticated && session?.auth0UserId) {
      LogRocket.identify(session?.auth0UserId, {
        name: session?.userFullName,
        email: session?.userEmail,
      });
    }
  }, [isAuthenticated, session?.auth0UserId]);

  if (
    !isAuthenticated &&
    appReady &&
    ![
      '/',
      '/login',
      '/reset-password',
      referralRouteMatch?.url.toLowerCase(),
      legacyReferralRouteMatch?.url.toLowerCase(),
      invitationRouteMatch?.url.toLowerCase(),
      verificationRouteMatch?.url.toLowerCase(),
      consentRouteMatch?.url.toLowerCase(),
      isCallbackCrossAuth?.url.toLowerCase(),
    ].includes(location.pathname?.toLowerCase())
  ) {
    return <FMLoader />;
  }

  if (isAuthenticated && location.pathname === '/') {
    return <Redirect to='/dashboard' />;
  }
  if (isAuthenticated && location.pathname === '/' && session?.isLoanOfficer) {
    if (lastLoanGuid) return <Redirect to={`/apply/${lastLoanGuid}`} />;
  }
  if (isAuthenticated && location.pathname === '/' && session?.isLoanOfficer) {
    return window.location.assign(env.LO_URL);
  }

  return (
    <div className='app flex-container flex-vertical'>
      <ErrorBoundary>
        <Helmet
          defaultTitle='Cinch by FM Home loans'
          titleTemplate='%s - Cinch by FM Home Loans'
        />
        {!appReady ? (
          <FMLoader loadingMessage='Please wait, Cinch is loading...' noTimer />
        ) : (
          <Suspense fallback={<FMLoader backgroundColor='#f2f2f2' />}>
            <AnonFeatureFlagsProvider>
              <Switch>
                <Route exact path='/:page(login)?' component={SignupSignin} />
                <Route
                  path='/register/:referralToken?/:guid?/:applicationIndex?/:pairFname?/:pairLname?/:pairEmail?'
                  component={SignupSignin}
                />
                <Route
                  path='/CreateAccount/:referralToken?/:guid?/:applicationIndex?/:pairFname?/:pairLname?/:pairEmail?'
                  component={SignupSignin}
                />
                <Route
                  path='/verify/:email?/:verificationCode?'
                  component={VerifyAccount}
                />
                <Route path='/consent/:hash' component={Consent} />
                <Route path='/callback-cross-auth' component={CrossAuth} />
                {!isAuthenticated && (
                  <Route
                    path='/:page(invite)/:invitationHash'
                    component={SignupSignin}
                  />
                )}
                <Route path='/reset-password' component={ResetPassword} />
                <Route path='*'>
                  <Header />
                  <main className='scroller flex flex-container flex-vertical'>
                    <Suspense fallback={<FMLoader backgroundColor='#f2f2f2' />}>
                      <Switch>
                        <Route path='/dashboard' component={Dashboard} />
                        <Route
                          path='/invite/:invitationHash'
                          component={RedeemInvitation}
                        />
                        <Route
                          path='/(apply|postapplication)/:guid'
                          component={LoanContainer}
                        />
                        <Route path='/settings'>
                          <UserSettings />
                        </Route>
                        <Route path='/callback' component={FMLoader} />
                        {process.env.NODE_ENV === 'development' && (
                          <Route path='/test'>
                            <Test />
                          </Route>
                        )}
                        <Route path='/logout'>
                          {() => {
                            logout();
                            return <FMLoader />;
                          }}
                        </Route>
                        <Route>
                          <Redirect to='/login' />
                        </Route>
                      </Switch>
                      <Footer />
                    </Suspense>
                  </main>
                </Route>
              </Switch>
              {process.env.NODE_ENV === 'development' && <ServerPicker />}
              {process.env.NODE_ENV === 'development' && (
                <LoanValidationState />
              )}
              {process.env.NODE_ENV === 'development' && <InvitationCreator />}
            </AnonFeatureFlagsProvider>
          </Suspense>
        )}
      </ErrorBoundary>
    </div>
  );
}

const mapStateToProps = ({
  app: {
    functionServer,
    apiServer,
    lastLoanGuid,
    warning,
    error,
    working,
    appReady,
  },
}) => ({
  functionServer,
  lastLoanGuid,
  apiServer,
  warning,
  error,
  savingLoan: working.some((item) => item.name?.startsWith('update')),
  appReady,
});
const mapAuthToProps = ({
  session,
  isAuthenticated,
  handleAuthentication,
  logout,
}) => ({
  session,
  idToken: session?.idToken,
  isAuthenticated: isAuthenticated(),
  handleAuthentication,
  logout,
});

export default connect(mapStateToProps)(authconnect(mapAuthToProps)(App));
