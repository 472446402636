/* eslint-disable react/display-name */
import React, { lazy, useState } from 'react';
import './index.scss';
import {
  AppBar,
  Toolbar,
  Container,
  Button,
  Menu,
  MenuItem,
  Divider,
  LinearProgress,
  IconButton,
} from '@material-ui/core';
import CinchLogo from 'src/components/CinchLogo';
import { authconnect } from 'src/Auth';
import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';
import { connect } from 'react-redux';
import { Route, Link } from 'react-router-dom';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import logo from 'src/assets/img/Cinch-Logos-03-noMargins-transparent.svg';
import Headroom from 'react-headroom';
import clsx from 'clsx';
import mobileMenuImg from 'src/assets/img/Header/mobile-menu.svg';
import mobileAvatarImg from 'src/assets/img/Header/mobile-avatar.svg';
import { LoanOfficerCard } from 'src/components/Header/LoanOfficerCard';

const BorrowerApplicationHeader = lazy(() =>
  import('src/components/Loan/Application/Header')
);
const PostApplicationHeader = lazy(() =>
  import('src/components/Loan/PostApplication/Header')
);

const guidMatchStr =
  '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

const LinkMenuItem = React.forwardRef((props, ref) => {
  const { to } = props;
  const renderLink = React.useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      React.forwardRef((itemProps, ref) => (
        <Link
          to={to}
          {...itemProps}
          innerRef={ref}
          className={itemProps.className}
        />
      )),
    [to]
  );
  return <MenuItem component={renderLink} {...props} ref={ref} />;
});

const BorrowerHeader = ({ session, logout, working }) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [mobileNav, setMobileNav] = React.useState(false);
  const closeMenu = () => {
    setMenuAnchor(null);
  };

  return (
    <Headroom>
      <AppBar
        className={clsx('app-header', isMobile && 'mobile')}
        position='relative'
        elevation={0}
      >
        {working && <LinearProgress color='secondary' className='workstate' />}
        <Toolbar className='header-top'>
          <Container className='flex-container'>
            <BrowserView viewClassName='flex flex-container'>
              <Link to='/'>
                <img src={CinchLogo} alt='logo' className='logo' />
              </Link>
              <span className='flex' />
              <Route
                path={`/:section(apply|postapplication)/:guid(${guidMatchStr})`}
              >
                {({ match }) => {
                  if (!match) return null;
                  return <LoanOfficerCard loanGuid={match.params.guid} />;
                }}
              </Route>
              <Button
                onClick={(e) => setMenuAnchor(e.currentTarget)}
                endIcon={<Icon path={mdiChevronDown} />}
              >
                {session ? session.userFullName : 'Loading'}
              </Button>
            </BrowserView>
            <MobileView
              viewClassName='flex flex-container'
              style={{ alignItems: 'center' }}
            >
              <div onClick={() => setMobileNav(true)}>
                <IconButton>
                  <img src={mobileMenuImg} alt='mobile menu' />
                </IconButton>
              </div>
              <Link to='/' className='flex flex-container flex-center'>
                <img className='logo' src={logo} alt='logo' />
              </Link>
              <div className='user-wrapper'>
                <div
                  className='user'
                  onClick={(e) => setMenuAnchor(e.currentTarget)}
                >
                  <img src={mobileAvatarImg} alt='mobile-user' />
                </div>
              </div>
            </MobileView>

            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={closeMenu}
              variant='menu'
              PaperProps={{
                style: {
                  width: 200,
                },
              }}
            >
              <LinkMenuItem to='/dashboard' onClick={closeMenu}>
                My loans
              </LinkMenuItem>
              <LinkMenuItem to='/settings' onClick={closeMenu}>
                Settings
              </LinkMenuItem>
              <Divider />
              <Route
                path={`/:section(apply|postapplication)/:guid(${guidMatchStr})`}
              >
                {({ match }) => {
                  return (
                    <MenuItem onClick={() => logout(match?.params?.guid)}>
                      Logout
                    </MenuItem>
                  );
                }}
              </Route>
            </Menu>
          </Container>
        </Toolbar>
        <Route path={`/:section(apply|postapplication)/:guid(${guidMatchStr})`}>
          {({ match }) => {
            if (!match) return null;
            return (
              <>
                <PostApplicationHeader
                  isMobile={isMobile}
                  mobileOpen={mobileNav}
                  onClose={() => setMobileNav(false)}
                  guid={match.params.guid}
                />
                {match.params.section === 'apply' && (
                  <BorrowerApplicationHeader
                    isMobile={isMobile}
                    mobileOpen={mobileNav}
                    onClose={() => setMobileNav(false)}
                    guid={match.params.guid}
                  />
                )}
              </>
            );
          }}
        </Route>
      </AppBar>
    </Headroom>
  );
};

const mapStateToProps = ({ app: { working } }) => ({
  working: working.length > 0,
});

const mapAuthToProps = ({ session, logout, isAuthenticated }) => ({
  session,
  isAuthenticated: isAuthenticated(),
  logout,
});

export default connect(mapStateToProps)(
  authconnect(mapAuthToProps)(BorrowerHeader)
);
