import { format, parse, parseISO } from 'date-fns';
import { fieldIds } from 'src/util/fields';

export * from './constant';
export * from './axios';
export * from './fields';

/**
 * Return a string with the first letter as uppercase
 * @param {string} name
 * @returns {string} Capitalized string
 */
export const capitalizeName = (name) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 * https://stackoverflow.com/a/48218209/3605190
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
export function mergeDeep (...objects) {
  const isObject = (obj) => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        if (oVal?.[0]?.feeType) {
          prev[key] = overwriteArrayItems(pVal, oVal, 'feeType');
        } else {
          prev[key] = pVal.concat(...oVal);
        }
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}

function overwriteArrayItems (pVal, oVal, identifier) {
  for (let i = 0; i <= oVal.length; i++) {
    const index = pVal.findIndex((item) => {
      return item?.[identifier] === oVal[i]?.[identifier];
    });
    if (index > -1) {
      pVal[index] = oVal[i];
    } else {
      pVal.concat(oVal[i]);
    }
  }
  return pVal;
}

/**
 * lazy deep clone
 * @template T
 * @param {T} obj
 * @returns {T}
 */
export const cloneDeep = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatISODateForDisplay = (isoString) => {
  if (!isoString) return '';
  return format(parseISO(isoString), 'MM/dd/yyyy');
};

export const formatDisplayForISO = (dateString) => {
  if (!dateString) return '';
  return format(
    parse(dateString, 'MM/dd/yyyy', new Date()),
    "yyyy-MM-dd'T'hh:mm:ss"
  );
};

/**
 * @param {number} bytes
 * @returns {string}
 */
export const formatBytes = (bytes) => {
  const i = !bytes ? 0 : Math.floor(Math.log(+bytes) / Math.log(1024));
  return (
    (+bytes / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][i]
  );
};

/**
 * @param {number | string | null | undefined} str
 * @returns {bool}
 */
export const isEmpty = (str) => {
  return str === null || str === undefined || str.toString() === '';
};

/**
 * @param {number | string | null | undefined} str
 * @returns {bool}
 */
export const isEmptyOrZero = (str) => {
  return isEmpty(str) || str.toString() === '0' || isNaN(+str);
};

/**
 * @param {import('src/types/loan').StoreLoan} loan
 * @param {{ [key: string]: string | number | boolean }} fields
 * @returns {string} loan purpose
 */
export const getLoanPurpose = (loan, fields) => {
  const property = loan.property;
  if (!property) return '';
  if (
    property.loanPurposeType === 'Purchase' &&
    fields[fieldIds.isPreApproval] === 'true'
  ) {
    return 'PreApproval';
  }
  return property.loanPurposeType || '';
};

export const returnEmptyStringIfUndefined = (value) => {
  return value === undefined ? '' : value;
};

export const getObjWithString = (obj, str) => {
  // helper for passed in objects as string into rhf components
  str = str?.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  str = str?.replace(/^\./, ''); // strip a leading dot
  if (!str) return;
  const nestedLevels = str.split('.');
  for (let i = 0, n = nestedLevels.length; i < n; ++i) {
    const key = nestedLevels[i];
    if (key in obj) {
      obj = obj[key];
    } else {
      return;
    }
  }
  return obj;
};

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * wait until a condition becomes true
 * @param {() => void} func
 * @param {number} delay
 */
export const waitUntil = (func, delay = 500) => {
  const poll = (resolve) => {
    if (func()) resolve();
    else setTimeout(() => poll(resolve), delay);
  };
  return new Promise(poll);
};

/**
 * @param {object} data
 * @returns {boolean}
 */
export const hasAnyChanges = (data) => {
  if (!data) return false;
  const keys = Object.keys(data);
  if (!keys.length) return false;

  for (const key of keys) {
    const item = data[key];
    if (typeof item !== 'object' || item === null) return true;
    if (item && typeof item === 'object') {
      if (Array.isArray(item)) {
        return (
          item.some((item) => typeof item !== 'object') ||
          item.some((item) => hasAnyChanges(item))
        );
      } else if (hasAnyChanges(item)) {
        return true;
      }
    }
  }

  return false;
};
