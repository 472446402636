import { Dispatch } from 'redux';
import { workstate } from 'src/actions';
import { Guid } from 'src/types';
import { apiAxios, hasAnyChanges, mergeDeep } from 'src/util';
import { OtherLiability, OtherLiabilityActionType } from './types';

export const createOtherLiability =
  (loanId: Guid, applicationId: Guid, data?: OtherLiability) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'create-otherliability', async () => {
      const res = await apiAxios.post(
        `loans/${loanId}/applications/${applicationId}/otherLiabilities`,
        data,
      );

      dispatch({
        type: OtherLiabilityActionType.ADD,
        data: res.data,
      });
    });
  };

export const updateOtherLiability =
  (
    loanId: Guid,
    applicationId: Guid,
    otherLiabilityId: Guid,
    data: OtherLiability,
  ) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'update-otherliability', async () => {
      if (!hasAnyChanges(data)) return;

      dispatch({
        type: OtherLiabilityActionType.UPDATE,
        data: { ...data, id: otherLiabilityId },
      });

      const res = await apiAxios.patch(
        `loans/${loanId}/applications/${applicationId}/otherLiabilities/${otherLiabilityId}`,
        data,
      );

      dispatch({
        type: OtherLiabilityActionType.UPDATE,
        data: mergeDeep(data, res.data),
      });
    });
  };

export const deleteOtherLiability =
  (loanId: Guid, applicationId: Guid, otherLiabilityId: Guid) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'delete-otherliability', async () => {
      await apiAxios.delete(
        `loans/${loanId}/applications/${applicationId}/otherLiabilities/${otherLiabilityId}`,
      );

      dispatch({
        type: OtherLiabilityActionType.DELETE,
        data: otherLiabilityId,
      });
    });
  };
