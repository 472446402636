import { Dispatch } from 'redux';
import { workstate } from 'src/actions';
import { Guid } from 'src/types';
import { apiAxios, hasAnyChanges, mergeDeep } from 'src/util';
import { ReoProperty, ReoPropertyActionType } from './types';

export const createReoProperty =
  (loanId: Guid, applicationId: Guid, data?: ReoProperty) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'create-property', async () => {
      const res = await apiAxios.post(
        `loans/${loanId}/applications/${applicationId}/properties`,
        data,
      );

      dispatch({
        type: ReoPropertyActionType.ADD,
        data: res.data,
      });
    });
  };

export const updateReoProperty =
  (loanId: Guid, applicationId: Guid, propertyId: Guid, data: ReoProperty) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'update-property', async () => {
      if (!hasAnyChanges(data)) return;

      dispatch({
        type: ReoPropertyActionType.UPDATE,
        data: { ...data, id: propertyId },
      });

      const res = await apiAxios.patch(
        `loans/${loanId}/applications/${applicationId}/properties/${propertyId}`,
        data,
      );

      dispatch({
        type: ReoPropertyActionType.UPDATE,
        data: mergeDeep(data, res.data),
      });
    });
  };

export const deleteReoProperty =
  (loanId: Guid, applicationId: Guid, propertyId: Guid) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'delete-property', async () => {
      await apiAxios.delete(
        `loans/${loanId}/applications/${applicationId}/properties/${propertyId}`,
      );

      dispatch({
        type: ReoPropertyActionType.DELETE,
        data: propertyId,
      });
    });
  };
