// @ts-nocheck
import { Dispatch } from 'redux';
import { workstate } from 'src/actions';
import { URLAAlternateNameActionType } from 'src/components/Loan/Application/Sections/BorrowerInfo/Inputs/AlternateNames/store/types';
import { ResidenceActionType } from 'src/components/Loan/Application/Sections/BorrowerInfo/Inputs/Residence/store/types';
import { AssetActionType } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Assets/store/types';
import { GiftGrantActionType } from '../../Sections/AssetsLiabilitiesInfo/Inputs/GiftsGrants/store/types';
import {
  ApplicationActionType,
  LoanActionType,
} from 'src/components/Loan/Application/store/actionTypes';
import { BorrowerOrCoBorrowerType, Guid } from 'src/types';
import { Borrower } from 'src/types/borrower';
import { Loan } from 'src/types/loan';
import { apiAxios, hasAnyChanges, mergeDeep } from 'src/util';
import { OtherAssetActionType } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/OtherAssets/store/types';
import { LiabilityActionType } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Liabilities/store/types';
import { OtherLiabilityActionType } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/OtherLiabilities/store/types';
import { OtherIncomeActionType } from 'src/components/Loan/Application/Sections/IncomeInfo/Inputs/OtherIncomes/store/types';
import { EmploymentActionType } from 'src/components/Loan/Application/Sections/EmploymentInfo/Inputs/Employments/store/types';
import { FieldActionType } from 'src/components/Loan/Application/store/fields/actions';
import { ReoPropertyActionType } from 'src/components/Loan/Application/Sections/AssetsLiabilitiesInfo/Inputs/Properties/store/types';

export const clearLoan = () => ({
  type: 'clear_loan_data',
});

const setResidences = (
  borrower: Borrower,
  applicantType: BorrowerOrCoBorrowerType,
) => ({
  type: ResidenceActionType.SET,
  data: borrower.residences
    .map((residence) => ({
      ...residence,
      owner: applicantType,
    }))
    .filter((x) => !x.id?.startsWith('MailingAddress')),
});

const setAlternateNames = (
  borrower: Borrower,
  applicantType: BorrowerOrCoBorrowerType,
) => ({
  type: URLAAlternateNameActionType.SET,
  data: borrower.urlaAlternateNames.map((name) => ({
    ...name,
    owner: applicantType,
  })),
});

const setEmployments = (
  borrower: Borrower,
  applicantType: BorrowerOrCoBorrowerType,
) => ({
  type: EmploymentActionType.SET,
  data: borrower.employment.map((employment) => ({
    ...employment,
    owner: applicantType,
  })),
});

export const getNewLoanData = (loanGuid: Guid) => (dispatch: Dispatch) => {
  return workstate(dispatch, 'fetch-loan-new', async () => {
    const res = await apiAxios.get(`loans/${loanGuid}`);

    const loan: Loan = res.data;

    const applications = loan.applications;
    const currentApplication = applications[0];

    if (currentApplication?.borrower?.urlaAlternateNames) {
      dispatch(
        setAlternateNames(
          currentApplication.borrower,
          BorrowerOrCoBorrowerType.Borrower,
        ),
      );
    }
    if (currentApplication?.coBorrower?.urlaAlternateNames) {
      dispatch(
        setAlternateNames(
          currentApplication.coBorrower,
          BorrowerOrCoBorrowerType.CoBorrower,
        ),
      );
    }

    if (currentApplication?.borrower?.residences) {
      dispatch(
        setResidences(
          currentApplication.borrower,
          BorrowerOrCoBorrowerType.Borrower,
        ),
      );
    }
    if (currentApplication?.coBorrower?.residences) {
      dispatch(
        setResidences(
          currentApplication.coBorrower,
          BorrowerOrCoBorrowerType.CoBorrower,
        ),
      );
    }

    if (currentApplication?.borrower?.employment) {
      dispatch(
        setEmployments(
          currentApplication.borrower,
          BorrowerOrCoBorrowerType.Borrower,
        ),
      );
    }
    if (currentApplication?.coBorrower?.employment) {
      dispatch(
        setEmployments(
          currentApplication.coBorrower,
          BorrowerOrCoBorrowerType.CoBorrower,
        ),
      );
    }

    if (currentApplication?.vods) {
      dispatch({
        type: AssetActionType.SET,
        data: currentApplication.vods,
      });
    }

    if (currentApplication?.otherAssets) {
      dispatch({
        type: OtherAssetActionType.SET,
        data: currentApplication.otherAssets,
      });
    }

    if (currentApplication?.giftsGrants) {
      dispatch({
        type: GiftGrantActionType.SET,
        data: currentApplication.giftsGrants,
      });
    }

    if (currentApplication?.vols) {
      dispatch({
        type: LiabilityActionType.SET,
        data: currentApplication.vols,
      });
    }

    if (currentApplication?.otherLiabilities) {
      dispatch({
        type: OtherLiabilityActionType.SET,
        data: currentApplication.otherLiabilities,
      });
    }

    if (currentApplication?.otherIncomeSources) {
      dispatch({
        type: OtherIncomeActionType.SET,
        data: currentApplication.otherIncomeSources,
      });
    }

    if (loan.customFields) {
      dispatch({
        type: FieldActionType.SET,
        data: loan.customFields,
      });
    }

    if (currentApplication?.reoProperties) {
      dispatch({
        type: ReoPropertyActionType.SET,
        data: currentApplication.reoProperties,
      });
    }

    // tidy up collections u shouldnt be touching directly in the loan, there are separate stores for these
    if (applications) {
      for (const i in applications) {
        delete applications[i].otherAssets;
        delete applications[i].vods;
        delete applications[i].vols;
        delete applications[i].reoProperties;
        delete applications[i].giftsGrants;
        delete applications[i].otherLiabilities;

        delete applications[i].borrower?.urlaAlternateNames;
        delete applications[i].borrower?.residences;
        delete applications[i].coBorrower?.urlaAlternateNames;
        delete applications[i].coBorrower?.residences;
      }
      dispatch({
        type: ApplicationActionType.SET,
        data: applications,
      });
    }

    delete loan.applications;
    delete loan.customFields;

    dispatch({
      type: LoanActionType.UPDATE,
      data: loan,
    });
  });
};

export const updateLoan =
  (loanGuid: Guid, data: Loan) => (dispatch: Dispatch, getState) => {
    return workstate(dispatch, 'update-loan', async () => {
      if (!hasAnyChanges(data)) return;
      const lockId = getState().app.lockId;

      dispatch({
        type: LoanActionType.UPDATE,
        data: data,
      });

      const res = await apiAxios.patch<Loan>(`loans/${loanGuid}`, data, {
        params: {
          lockId,
        },
      });

      const loan = res.data;
      delete loan.applications;

      dispatch({
        type: LoanActionType.UPDATE,
        data: mergeDeep(data, loan),
      });

      if (loan?.property?.addressLineText !== undefined) {
        dispatch({
          type: 'update_borrower_loan_application',
          data: {
            loanGuid,
            propertyName: loan.property.addressLineText,
          },
        });
      }
      if (loan?.baseLoanAmount !== undefined) {
        dispatch({
          type: 'update_borrower_loan_application',
          data: {
            loanGuid,
            loanAmount: loan.baseLoanAmount,
          },
        });
      }
    });
  };
