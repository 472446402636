import { Dispatch } from 'redux';
import { workstate } from 'src/actions';
import { Guid } from 'src/types';
import { apiAxios, hasAnyChanges, mergeDeep } from 'src/util';
import { GiftGrant, GiftGrantActionType } from './types';

export const createGiftGrant =
  (loanId: Guid, applicationId: Guid, data?: GiftGrant) =>
  async (dispatch: Dispatch) => {
    return workstate(dispatch, 'create-gift-grant', async () => {
      const res = await apiAxios.post(
        `loans/${loanId}/applications/${applicationId}/giftsGrants`,
        data,
      );

      dispatch({
        type: GiftGrantActionType.ADD,
        data: res.data,
      });
    });
  };

export const updateGiftGrant =
  (loanId: Guid, applicationId: Guid, giftGrantId: Guid, data: GiftGrant) =>
  async (dispatch: Dispatch) => {
    return workstate(dispatch, 'update-asset', async () => {
      if (!hasAnyChanges(data)) return;

      dispatch({
        type: GiftGrantActionType.UPDATE,
        data: { ...data, id: giftGrantId },
      });

      const res = await apiAxios.patch(
        `loans/${loanId}/applications/${applicationId}/giftsGrants/${giftGrantId}`,
        data,
      );

      dispatch({
        type: GiftGrantActionType.UPDATE,
        data: mergeDeep(data, res.data),
      });
    });
  };

export const deleteGiftGrant =
  (loanId: Guid, applicationId: Guid, giftGrantId: Guid) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'delete-asset', async () => {
      await apiAxios.delete(
        `loans/${loanId}/applications/${applicationId}/giftsGrants/${giftGrantId}`,
      );

      dispatch({
        type: GiftGrantActionType.DELETE,
        data: giftGrantId,
      });
    });
  };
