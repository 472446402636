import { Dispatch } from 'redux';
import { workstate } from 'src/actions';
import {
  URLAAlternateName,
  URLAAlternateNameActionType,
} from 'src/components/Loan/Application/Sections/BorrowerInfo/Inputs/AlternateNames/store/types';
import { BorrowerOrCoBorrowerType, Guid } from 'src/types';
import { apiAxios, hasAnyChanges, mergeDeep } from 'src/util';

export const createAlternateName =
  (
    loanId: Guid,
    applicationId: Guid,
    applicantType: BorrowerOrCoBorrowerType,
    data?: URLAAlternateName,
  ) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'create-alternate-name', async () => {
      const res = await apiAxios.post(
        `loans/${loanId}/applications/${applicationId}/${applicantType.toLowerCase()}/alternateNames`,
        data,
      );

      dispatch({
        type: URLAAlternateNameActionType.ADD,
        data: {
          ...res.data,
          owner: applicantType,
        },
      });
    });
  };

export const updateAlternateName =
  (
    loanId: Guid,
    applicationId: Guid,
    applicantType: BorrowerOrCoBorrowerType,
    nameId: Guid,
    data: URLAAlternateName,
  ) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'update-alternate-name', async () => {
      if (!hasAnyChanges(data)) return;

      dispatch({
        type: URLAAlternateNameActionType.UPDATE,
        data: data,
      });

      const res = await apiAxios.patch(
        `loans/${loanId}/applications/${applicationId}/${applicantType.toLowerCase()}/alternateNames/${nameId}`,
        data,
      );

      dispatch({
        type: URLAAlternateNameActionType.UPDATE,
        data: mergeDeep(data, res.data),
      });
    });
  };

export const deleteAlternateName =
  (
    loanId: Guid,
    applicationId: Guid,
    applicantType: BorrowerOrCoBorrowerType,
    nameId: Guid,
  ) =>
  (dispatch: Dispatch) => {
    return workstate(dispatch, 'delete-alternate-name', async () => {
      await apiAxios.delete(
        `loans/${loanId}/applications/${applicationId}/${applicantType.toLowerCase()}/alternateNames/${nameId}`,
      );

      dispatch({
        type: URLAAlternateNameActionType.DELETE,
        data: nameId,
      });
    });
  };
